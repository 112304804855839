/*global Flickity, WOW, Stickyfill */
console.log('hello world');

new Flickity( '.js_slider', {
  contain: true,
  initialIndex: 2,
  cellAlign: 'center',
  wrapAround: true,
  autoPlay: 5000
});

new WOW().init();

var stickyElements = document.getElementsByClassName('js-sticky');
for (var i = stickyElements.length - 1; i >= 0; i--) {
    Stickyfill.add(stickyElements[i]);
}
